/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { Link } from 'react-router-dom'

import useScreenSize from '../../hooks/useScreenSize'
import useModules from '../../hooks/useModules'
import ExternalLink from '../ExternalLink'
import { FooterStyles, Links } from './Footer.styles'

const Footer: React.FC = () => {
  const {
    brand,
    footerLogo,
    footerLogoSize,
    footerCopyrightId,
    footerBackground,
    privacyPolicyLink,
    cookiePolicyLink,
    companyLink,
    siteTermsLink,
  } = useTheme()
  const { isTablet, isMobile } = useScreenSize()
  const { t } = useTranslation()
  const modules = useModules()
  const backgroundImage = isMobile
    ? footerBackground?.mobileImage
    : isTablet
    ? footerBackground?.tabletImage
    : footerBackground?.desktopImage

  const currentYear = new Date().getFullYear().toString()
  const contactModule = modules.find((m) => m.name === 'contact')

  if (!contactModule) throw new Error('Missing contact module config in Footer')

  const LinkComponent = ({ children }: { children: string }) =>
    contactModule.external ? (
      <ExternalLink to={contactModule.to}>{children}</ExternalLink>
    ) : (
      <Link to={contactModule.to}>{children}</Link>
    )

  return (
    <FooterStyles data-testid="footer-background" backgroundImage={backgroundImage} id="footer-styles">
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        {footerLogo ? (
          <img src={footerLogo} style={{ width: footerLogoSize }} alt="Brand logo" />
        ) : (
          <div
            css={css`
              width: 200px;
              height: 50px;
              background-color: white;
            `}
          />
        )}

        <p>
          {footerCopyrightId
            ? t(footerCopyrightId, { currentYear })
            : `White label Copyright ${currentYear}. All rights reserved.`}
        </p>
      </div>
      <Links>
        <ul>
          <li>
            <ExternalLink to={companyLink}>{t('company')}</ExternalLink>
          </li>
          {brand !== 'demag' && (
            <li>
              <LinkComponent>{contactModule.menuItemTitle!}</LinkComponent>
            </li>
          )}
        </ul>
        <ul>
          <li>
            <ExternalLink to={siteTermsLink}>{t('site_terms')}</ExternalLink>
          </li>
          <li>
            <ExternalLink to={privacyPolicyLink}>{t('data_protection')}</ExternalLink>
          </li>
          <li>
            <ExternalLink to={cookiePolicyLink}>{t('cookie_policy')}</ExternalLink>
          </li>
        </ul>
      </Links>
    </FooterStyles>
  )
}

export default Footer
